import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import VuetablePaginationDropdown from 'vuetable-2/src/components/VuetablePaginationDropdown'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        VuetablePaginationDropdown,
        VuetablePaginationInfo,
        vSelect,
        axios,
    },
    data: function() {
        return {
            pageTitle: "Compte Tiers / Auxiliaires",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            listeCompte: "",

            crudform: {
                parent_id: "",
                plan_comptable_id: "",
                num_compte: "",
                num_compte_parent: "",
                description: "",
                intitule: "",
            },
            fields: [
                {
                    name: 'num_compte_parent',
                    title: 'Compte parent',
                    sortField: 'num_compte',
                    width: "150px",
                    dataClass: "text-bold",
                },
                {
                    name: 'num_compte',
                    title: 'Compte Aux.',
                    sortField: 'num_compte',
                    width: "150px",
                    dataClass: "text-bold",
                },
                {
                    name: 'libelle',
                    title: 'Libellé',
                    sortField: 'libelle',
                },
                {
                    name: 'description',
                    title: 'Description',
                    sortField: 'description'
                },
                {
                    name: 'actions',
                    title: 'Action',
                    width: '90px',
                    dataClass: "text-center",
                }

            ],
            sortOrder: [
                { field: 'num_compte', direction: 'asc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'fas fa-chevron-up',
                    descendingIcon: 'fas fa-chevron-down',
                    handleIcon: 'fas fa-spinner',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Compte parent': 'num_compte_parent',
                'Compte aux': 'num_compte',
                'Libellé': 'libelle',
                'Descritpion': 'description',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],

        }
    },
    methods: {
        openModal(){
            this.$bvModal.show("crudmodal");
        },
        closeModal(){
            this.$bvModal.hide("crudmodal");
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/compteauxiliaires/delete/"+rowData.id).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log(response.data.message);
                            that.setFilter();
                            Vue.$toast.open({
                                message: response.data.message,
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
            
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.tabledata = this.$refs.vuetable.$data.tableData;
            this.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                $(".vs__search").trigger("blur");
                $(".vs__search").focus();
                //console.log(filtre);
            //axios.get("/plancomptable/getall").then(response => {
                //this.listeCompte = response.data;
            });
        },
        initCompteInfo(item) {
            console.log(item);
            const result = this.listeCompte.find( ({ id }) => id === item );
            console.log(result);
            this.crudform.num_compte_parent = result.num_compte;
            this.crudform.description = result.intitule;
            this.crudform.plan_comptable_id = result.id;
        },
        addRow(){
            this.crudmodaltitle = "Ajouter un compte tiers / auxiliaire";
            this.crudform = {
                parent_id: "",
                num_compte: "",
                intitule: "",
                auxiliaires: "",
                observations: "",
            }
            this.initListCompte('');
            this.openModal();
        },
        editRow(rowData) {
            this.initListCompte(rowData.num_compte);
            //alert("You clicked edit on" + JSON.stringify(rowData));
            /*axios.get("/plancomptable/get/"+rowData.id).then(response => {
                //console.log(response.data[0]);
                this.crudform = response.data[0];
            });*/
            this.crudform = rowData;

            this.crudform.parent_id = rowData.plan_comptable_id;
            
            this.crudmodaltitle = "Editer un compte tiers / auxiliaire";
            this.openModal();
        },
        onSubmit() {
            var that =  this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/compteauxiliaires/action", $("#formulaire").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log("Enregistrement effectué avec succès!");
                            that.closeModal();
                            that.setFilter();
                            Vue.$toast.open({
                                message: 'Enregistrement effectué avec succès!',
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            

            
        }
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        
        // axios.get("/plancomptable/showrecursive").then(response => {
        //     this.table = response.data;
        // });

        //this.initListCompte('');
    },
    mounted: function() {
        // var that = this;
        this.initListCompte('');
        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}